@font-face {
    font-family: 'SF UI Display';
    src: url('SFUIDisplay-Regular.eot');
    src: local('SF UI Display Regular'), local('SFUIDisplay-Regular'),
        url('SFUIDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('SFUIDisplay-Regular.woff2') format('woff2'),
        url('SFUIDisplay-Regular.woff') format('woff'),
        url('SFUIDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SF UI Display';
    src: url('SFUIDisplay-Thin.eot');
    src: local('SF UI Display Thin'), local('SFUIDisplay-Thin'),
        url('SFUIDisplay-Thin.eot?#iefix') format('embedded-opentype'),
        url('SFUIDisplay-Thin.woff2') format('woff2'),
        url('SFUIDisplay-Thin.woff') format('woff'),
        url('SFUIDisplay-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'SF UI Display';
    src: url('SFUIDisplay-Medium.eot');
    src: local('SF UI Display Medium'), local('SFUIDisplay-Medium'),
        url('SFUIDisplay-Medium.eot?#iefix') format('embedded-opentype'),
        url('SFUIDisplay-Medium.woff2') format('woff2'),
        url('SFUIDisplay-Medium.woff') format('woff'),
        url('SFUIDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SF UI Display';
    src: url('SFUIDisplay-Bold.eot');
    src: local('SF UI Display Bold'), local('SFUIDisplay-Bold'),
        url('SFUIDisplay-Bold.eot?#iefix') format('embedded-opentype'),
        url('SFUIDisplay-Bold.woff2') format('woff2'),
        url('SFUIDisplay-Bold.woff') format('woff'),
        url('SFUIDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}