
@font-face {
	font-family: 'BNR'; 
	src: url(../src/fonts/bnr.ttf); 
}

body {
    font-family: 'SF UI Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.logo {
    height: 50px;
    margin: 25px 8px 22px 8px;
    background: url(../src/img/logo.png) no-repeat center center;
    background-size: contain;
}

.site-layout-sub-header-background {
    background: #fff;
}

.ant-layout-header {
    height: 100px;
    border-bottom: 3px solid #fbfbfb;
}

.site-page-header {
    height: 100%;
}

.ant-page-header-heading {
    align-items: center;
    height: 100%;
}

.ant-page-header-heading-left {
    margin: 4px 0 4px 40px;
}

.site-layout-background {
    background: transparent;
}

[data-theme="dark"] .site-layout-sub-header-background {
    background: #141414;
}

.login-form-button {
    width: 100%;
}

.authform {
    justify-content: center;
}

.authpage {
    width: 100%;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loadpage {
    justify-content: center;
}

.bg-auth {
    background: url(../src/img/bg.png) fixed no-repeat center center;
    background-size: cover;
}

.buttmar > button {
    margin-right: 8px;
    margin-bottom: 12px;
}

@media screen and (max-width: 650px) {

    .bg-auth {
        background: url(../src/img/bgm.png) fixed no-repeat center center;
    }
    
}

@media screen and (max-width: 500px) {

    .usercard {
        flex-direction: column !important;
    }

    .usercard > div:first-child {
        display: flex;
        justify-content: center;
    }

    .usercard > div {
        margin: 10px 0 !important;
    }
    
}

.gradient {
	color: transparent;    
	-webkit-background-clip: text;
	background-clip: text;
	background-image: linear-gradient(135deg, #0daaeb, #9054cb);
}

.auth_remember span {
    color: #FFFFFF !important;
    background-color: none !important;
}

.authpageblock {
    padding: 24px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.authtitle {
    font-family: 'BNR';
    text-align: center;
    color: #FFFFFF;
}

.authtitle p:nth-child(2) {
    font-size: 35px;
    margin: 0 !important;
    padding: 0 !important;
}

.authtitle p:nth-child(3) {
    font-size: 60px;
    line-height: 40px;
}

.inauth {
    border-radius: 25px;
    height: 40px;
    color: #8a8a8a;
}

.inauths {
    border-radius: 25px;
}

.tableimg {
    max-width: 50px;
}

.tableimg > img {
    width: 100%;
}

.spincenter {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.alcenter {
    align-items: unset;
}

.form800 {
    max-width: 800px;
}

.searchinput {
    max-width: 200px;
    display: flex;
    align-items: center;
    margin-left: auto;
}

.searchinput > input {
    color: #FFFFFF;
    border-color: unset !important;
    background: transparent;
    border: none;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
    box-shadow: none !important;
}

.buttmar {
    margin-top: 10px;
}

.searchinput .ant-form-item {
    margin-bottom: 0;
}

.ant-tabs-tab-disabled {
    display: none;
}

.ant-tabs-tab-active {
    display: block;
}

.toh {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    margin-left: 5px;
}

.servicegroup .ant-row {
    align-items: stretch;
}

.servicegroup div > .ant-col, .servicegroup div > .ant-col, .servicegroup div > .ant-col > .ant-list-item, .servicegroup div > .ant-col > .ant-list-item > .ant-card {
    height: 100%;
}

.ant-row > div {
    margin-bottom: 20px;
}

.servicegroup {
    margin-bottom: 50px;
}

.servicegroup:last-child {
    margin-bottom: 0;
}

.ant-space-vertical {
    width: 100%;
}

.alcenter {
    width: 100%;
    display: inline-flex;
}

.usercard {
    display: flex;
    flex-direction: row;
}

.usercard > div {
    flex: 0 1;
    margin: 10px;
}

.usercard > div:first-child {
    display: flex;
    align-items: center;
}

.usercard > div:last-child {
    flex: 1 0;
    padding: 24px 40px;
    background-color: #ffffff;
    border-radius: 30px;
}

.usercard> .trans:last-child {
    background-color: transparent;
}

.ant-layout-sider-zero-width-trigger {
    box-shadow: 5px 0 5px 0 rgb(0 0 0 / 15%);
    border-radius: 0 4px 4px 0;
    background: #262528 !important;
    color: #FFFFFF !important;
    top: 25px;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected, .ant-menu-item:active, .ant-menu-submenu-title:active {
    background-color: transparent;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
    display: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 25px;
    border-radius: 20px 0 0 20px;
    background-color: #fafafa;
    content: '';
}

.ant-menu-submenu-selected {
    color: #000000;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
    color: #000000 !important;
}

li.ant-menu-item > span {
    position: relative;
}

.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover, .ant-menu-item a:hover, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #7e7e7e;
}

a.ant-typography, .ant-typography a {
    color: rgba(0, 0, 0, 0.85);
}

.ant-list-item-meta-title > a:hover {
    color: rgba(48, 48, 48, 0.85);
}

a.ant-typography:active, .ant-typography a:active {
    color: rgba(32, 32, 32, 0.85);
}

.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
    width: 100% !important;
}

.ant-menu-item {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 40px !important;
    padding-right: 0 !important;
    overflow: visible !important;
}

.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
    margin-top: 0;
    margin-bottom: 0;
}

.ant-menu-sub.ant-menu-inline {
    background: transparent;
}

.ant-menu-item > span {
    flex: 0 1;
}

.ant-menu-item > span:last-child {
    flex: 1 0;
}

.ant-menu-item > span:last-child::before {
    width: 20px;
    height: 20px;
    right: 0;
    top: -20px;
    display: none;
    position: absolute;
    background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Слой_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20 20' style='enable-background:new 0 0 20 20;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23fafafa;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M20,20H0c11,0,20-9,20-20V20z'/%3E%3C/g%3E%3C/svg%3E%0A") no-repeat;
    content: "";
}

.ant-menu-item > span:last-child::after {
    width: 20px;
    height: 20px;
    right: 0;
    bottom: -20px;
    display: none;
    position: absolute;
    background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Слой_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20 20' style='enable-background:new 0 0 20 20;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23fafafa;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M20,0v20C20,9,11,0,0,0H20z'/%3E%3C/g%3E%3C/svg%3E%0A") no-repeat;
    content: "";
}

.ant-menu-item-selected > span:last-child::before, .ant-menu-item-selected > span:last-child::after {
    display: inline-block;
}

.ant-menu {
    background: transparent;
    color: #FFFFFF;
    padding-top: 20px;
}

.ant-menu-item a {
    color: #FFFFFF;
}

.ant-card-meta-title {
    text-align: center;
    color: #575757;
    font-size: 20px;
}

.ant-card-meta-description {
    text-align: center;
    color: #828282;
    font-size: 16px;
}

.homescreen {
    background: url(../src/img/bg2.png) fixed no-repeat top center, #fafafa;
    background-size: cover;
}

.menuscreen {
    background: url(../src/img/bg3.png) no-repeat top center, #29282c;
    background-size: cover;
}

.dividermenu {
    margin: 10px 25px;
    background: #FFFFFF;
    min-width: auto;
    width: auto;
}

.shadowscreen {
    -webkit-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
    background: #f7f7f7;
    border-radius: 30px;
}

 .grad {
    transition: all 1s ease;
    border: none;
    background: rgb(117,167,234) !important;
    background: linear-gradient(270deg, rgba(117,167,234,1) 0%, rgba(238,146,219,1) 100%) !important;
    color: #FFFFFF !important;
}

 .grad:hover, .grad:active {
    background: rgb(101,155,228) !important;
    background: linear-gradient(270deg, rgba(101,155,228,1) 0%, rgba(224,118,202,1) 100%) !important;
    color: #FFFFFF !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #575757;
}

.ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
    color: #727272;
}

.ant-tabs-ink-bar {
    background: #575757;
}

.wrow {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
}

.wcol {
    margin: 40px 0;
    padding: 20px;
    min-width: 300px;
    flex: 1 1 calc(100%/2 - 8rem);
}

.gray {
    color: #828282 !important;
}

.gray2 {
    color: #575757 !important;
}

.textcenter {
    text-align: center;
}

.ant-result .ant-result-title{
    color: #575757;
    font-weight: 600;
}

.ant-result .ant-result-subtitle {
    color: #828282;
}

.tableempty {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

table {
    -webkit-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
    background: #f7f7f7;
    border-radius: 30px;
}

.ant-table-thead > tr > th, .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: transparent;
    background: unset;
    border-bottom: unset;
}

.ant-table-tbody > tr:last-child > td {
    border-bottom: none;
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: transparent;
}

.ant-tabs {
    overflow: visible !important;
}

.cardnone {
    background: none !important;
    box-shadow: none !important;
}

.ant-card {
    border-radius: 30px;
    box-shadow: 0px 4px 30px 0px rgb(0 0 0 / 20%);
}

.ant-card-cover img {
    border-radius: 30px 30px 0 0;
}

.ant-card-actions {
    border-radius:  0 0 30px 30px;
}

.coldesc {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
}